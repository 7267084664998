// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media(min-width:768px){.Profile_profile_1f4Hk>div{display:flex}}.Profile_h1_2o9K1{text-align:left}@media(min-width:768px){.Profile_h1_2o9K1{text-align:right}}.Profile_p_E6SKK{text-align:left}@media(min-width:768px){.Profile_p_E6SKK{margin-left:40px}}", ""]);
// Exports
exports.locals = {
	"profile": "Profile_profile_1f4Hk",
	"h1": "Profile_h1_2o9K1",
	"p": "Profile_p_E6SKK"
};
module.exports = exports;
