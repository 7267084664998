<template>
  <p :class="$style.p">
    <slot/>
  </p>
</template>

<script>
export default {
  name: 'P',
}
</script>

<style module lang="scss">
.p {
  color: #fff;
  cursor: default;
  font-size: 1.8em;
  line-height: 1.6;
  margin-bottom: 27px;
  margin-top: 27px;
}
</style>
