<template>
  <NoSSR>
    <span :class="computedClass">
      <i :class="$style.i"/>
    </span>
  </NoSSR>
</template>

<script>
import NoSSR from 'vue-no-ssr'

export default {
  name: 'Loading',
  components: {
    NoSSR,
  },
  data() {
    return {
      dataIsMounted: false,
    }
  },
  computed: {
    computedClass() {
      return [
        this.$style.span,
        {
          [this.$style['--is-mounted']]: this.dataIsMounted,
        },
      ]
    },
  },
  mounted() {
    setTimeout(() => {
      this.dataIsMounted = true
    }, 0)
  },
}
</script>

<style module lang="scss">
.span {
  display: inline-block;
}

.i {
  display: block;
  opacity: 0;
  transform: scale(0);
}

.--is-mounted .i {
  opacity: 1;
  transform: scale(1);
  transition: opacity .5s, transform .5s;
}

@keyframes spin {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.i::before {
  animation-duration: 1s;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  border: 2px solid #fff;
  border-top-color: transparent;
  border-radius: 36px;
  content: '';
  display: block;
  height: 32px;
  width: 32px;
}
</style>
