import Vue from 'vue'
import { ApolloClient } from 'apollo-client'
// import { HttpLink } from 'apollo-link-http'
import { PrismicLink } from 'apollo-link-prismic'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'

import introspectionQueryResultData from '@/fragmentTypes.json'

// Install the vue plugin
Vue.use(VueApollo)

// Create the apollo client
export function createApolloClient(ssr = false) {
  const prismicLink = new PrismicLink({
    // You should use an absolute URL here
    uri: 'https://pajaron.cdn.prismic.io/graphql'
  })

  const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData: introspectionQueryResultData })

  const cache = new InMemoryCache({ fragmentMatcher })

  // If on the client, recover the injected state
  if (!ssr) {
    if (typeof window !== 'undefined') {
      const state = window.__APOLLO_STATE__
      if (state) {
        // If you have multiple clients, use `state.<client_id>`
        cache.restore(state.defaultClient)
      }
    }
  }

  const apolloClient = new ApolloClient({
    link: prismicLink,
    cache,
    ...(ssr
      ? {
          // Set this on the server to optimize queries when SSR
          ssrMode: true
        }
      : {
          // This will temporary disable query force-fetching
          ssrForceFetchDelay: 100
        })
  })

  return apolloClient
}
