<template>
  <div id="app" :class="computedClass">
    <div :class="$style.body">
      <Section :class="$style.header" tag="header">
        <router-link :class="$style['logo-wrapper']" to="/">
          <svg viewBox="0 0 1176.204 540.024" preserveAspectRatio="xMinYMid meet" v-if="!dataDidLogoAnimationEnd" v-bind:ref="'logo'" :class="computedLogoClass" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><title>Pajaron</title><g fill="none" stroke="#FFF" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"><path d="M208 35.614l-100 300" stroke-dasharray="320"><animate attributeName="stroke-dashoffset" from="320" to="0" dur="320ms" fill="freeze"/></path><path d="M8 35.614c600-100 400 100 100 150" stroke-dasharray="740 1060"><animate attributeName="stroke-dashoffset" from="1060" to="0" dur="1060ms" fill="freeze"/></path><path d="M408 235.614s0-100-100-50 100 50 100 50z" stroke-dasharray="320 1380"><animate attributeName="stroke-dashoffset" from="1380" to="0" dur="1380ms" fill="freeze"/></path><path d="M408 235.614c100 0 100-50 100-50 0 600-500 300 100 0 100-50 100 50 100 50" stroke-dasharray="1185 2565"><animate attributeName="stroke-dashoffset" from="2565" to="0" dur="2565ms" fill="freeze"/></path><path d="M708 235.614s0-100-100-50 100 50 100 50z" stroke-dasharray="320 2885"><animate attributeName="stroke-dashoffset" from="2885" to="0" dur="2885ms" fill="freeze"/></path><path d="M708 235.614c100 0 50-100 150-50" stroke-dasharray="180 3065"><animate attributeName="stroke-dashoffset" from="3065" to="0" dur="3065ms" fill="freeze"/></path><path d="M958 185.614c-50 0-100 50 0 50 50 0 100-50 0-50z" stroke-dasharray="275 3340"><animate attributeName="stroke-dashoffset" from="3340" to="0" dur="3340ms" fill="freeze"/></path><path d="M1058 235.614c0-50 150-100 100 0" stroke-dasharray="190 3530"><animate attributeName="stroke-dashoffset" from="3530" to="0" dur="3530ms" fill="freeze"/></path></g></svg>
          <svg viewBox="0 0 1176.204 540.024" preserveAspectRatio="xMinYMid meet" v-else="" :class="$style.logo" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><title>Pajaron</title><g fill="none" stroke="#FFF" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"><path d="M208 35.614l-100 300" stroke-dasharray="320"/><path d="M8 35.614c600-100 400 100 100 150" stroke-dasharray="740 1060"/><path d="M408 235.614s0-100-100-50 100 50 100 50z" stroke-dasharray="320 1380"/><path d="M408 235.614c100 0 100-50 100-50 0 600-500 300 100 0 100-50 100 50 100 50" stroke-dasharray="1185 2565"/><path d="M708 235.614s0-100-100-50 100 50 100 50z" stroke-dasharray="320 2885"/><path d="M708 235.614c100 0 50-100 150-50" stroke-dasharray="180 3065"/><path d="M958 185.614c-50 0-100 50 0 50 50 0 100-50 0-50z" stroke-dasharray="275 3340"/><path d="M1058 235.614c0-50 150-100 100 0" stroke-dasharray="190 3530"/></g></svg>
        </router-link>

        <nav :class="$style.nav">
          <router-link :class="$style['nav-link']" to="/gallery">Gallery</router-link>
        </nav>
      </Section>

      <main :class="$style.main">
        <transition :name="computedDidRouteChange || dataIsMounted ? 'fade' : null">
          <router-view/>
        </transition>
      </main>

      <Loading :class="$style.loading" v-if="computedIsRouteLoading"/>

      <LazyHydrate never>
        <Section :class="$style.footer" tag="footer" compact>
          <small :class="$style.copy">&copy; {{ computedYear }} Pajaron</small>
        </Section>
      </LazyHydrate>
    </div>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

import Loading from '@/components/Loading'
import Section from '@/components/Section'

export default {
  components: {
    LazyHydrate,
    Loading,
    Section,
  },

  data() {
    return {
      dataDidLogoAnimationBegin: false,
      dataDidLogoAnimationEnd: false,
      dataIsMounted: false,
      dataIsWindowScrolled: false,
    }
  },

  computed: {

    computedClass() {
      return [
        this.$style.app,
        {
          [this.$style['--header-is-hero']]: this.computedIsRouteHome,
          [this.$style['--is-mounted']]: this.dataIsMounted,
          [this.$style['--route-is-loading']]: this.computedIsRouteLoading,
          [this.$style['--window-is-scrolled']]: this.dataIsWindowScrolled,
        },
      ]
    },

    computedDidRouteChange() {
      if (typeof window !== 'undefined') {
        return window.__INITIAL_STATE__?.route.name !== this.$route.name
      }

      return true
    },

    computedIsRouteHome() {
      return this.$route.path === '/'
    },

    computedIsRouteLoading() {
      return this.$store.state.isRouteLoading
    },

    computedLogoClass() {
      return [
        this.$style.logo,
        {
          [this.$style['logo--animation-did-begin']]: this.dataDidLogoAnimationBegin,
        },
      ]
    },

    computedNoscriptHtml() {
      return `<style>.${this.$style.logo}{opacity:1}</style>`
    },

    computedYear() {
      const date = new Date()

      return date.getFullYear()
    },

  },

  methods: {

    addLogoEventListeners() {
      const animates = this.$refs.logo?.querySelectorAll('animate')

      if (!animates?.length) return

      animates[0].addEventListener('beginEvent', () => {
        this.dataDidLogoAnimationBegin = true
      })

      animates[animates.length - 1].addEventListener('endEvent', () => {
        // this.dataDidLogoAnimationEnd = true
      })
    },

    onWindowScroll() {
      if (window.scrollY > 0) {
        this.dataIsWindowScrolled = true

        return
      }

      this.dataIsWindowScrolled = false
    },

  },

  mounted() {
    setTimeout(() => {
      this.dataIsMounted = true
    }, 0)

    window.addEventListener('scroll', this.onWindowScroll)
    this.addLogoEventListeners()
  },

  updated() {
    this.addLogoEventListeners()
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll)
  },

  metaInfo() {
    return {

      noscript: [
        { innerHTML: this.computedNoscriptHtml },
      ],

      titleTemplate(title) {
        if (!title) {
          return 'pajaron.io'
        }

        return `${title} | pajaron.io`
      },

      __dangerouslyDisableSanitizers: ['noscript'],

    }
  },
}
</script>

<style module lang="scss">
@import url('https://p.typekit.net/p.css?s=1&k=mys3gek&ht=tk&f=10879.10881.10884.10886&a=14960202&app=typekit&e=css');

@font-face {
  font-family: 'futura-pt';
  src: url('https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('woff'),
    url('https://use.typekit.net/af/ae4f6c/000000000000000000010096/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'futura-pt';
  src: url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'futura-pt';
  src: url('https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('woff'),
    url('https://use.typekit.net/af/2cd6bf/00000000000000000001008f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3')
      format('opentype');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

// @font-face {
//   font-family: 'futura-pt';
//   src: url('https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
//       format('woff2'),
//     url('https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
//       format('woff'),
//     url('https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
//       format('opentype');
//   font-display: swap;
//   font-style: normal;
//   font-weight: 700;
// }

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  background-color: #6a5464;
  height: 100%;
  margin: 0;
}

.app {
  font-family: futura-pt, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  text-align: center;
}

.body {
  background-color: #715a6b;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}

.header {
  overflow: visible !important;

  > div {
    padding-bottom: 0;
    padding-top: 18px;
    position: relative;
    transition: padding-top .5s;
  }
}

.--header-is-hero .header {
  > div {
    padding-top: 9.648%;
  }
}

.logo-wrapper {
  left: 0;
  max-width: 15%;
  min-width: 54.89px;
  position: absolute;
  text-decoration: none;
  top: 100%;
  transition: max-width .5s;
  width: 19.604%;
}

.--header-is-hero .logo-wrapper {
  max-width: 19.604%;
}

.logo {
  opacity: 0;
  outline: none;
  vertical-align: top;
  width: 100%;

  g {
    stroke-width: 16;
  }
}

.logo--animation-did-begin {
  opacity: 1;
}

.nav {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin-top: -9px;
  max-width: 15%;
  min-width: 54.89px;
  position: absolute;
  right: -10px;
  top: 100%;
  transition: max-width .5s;
  width: 19.604%;
}

.--header-is-hero .nav {
  max-width: 19.604%;
}

.nav::before {
  content: '';
  display: block;
  padding-top: 20.41%;
  width: 0;
}

.nav-link {
  color: #e8e2e6;
  font-size: 1.6em;
  font-weight: 500;
  line-height: .7;
  padding: 9px 10px;
  text-decoration: none;
  text-transform: lowercase;
}

.nav-link:hover {
  color: #fff;
}

.nav-link:global(.router-link-active) {
  color: #fff;
}

.main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  float: left;
  width: 100%;
}

.--route-is-loading .main > * {
  opacity: .25;
  transition: opacity .5s;
}

.loading {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.footer {
  left: 0;
  position: absolute;
  right: 0;
  top: 100%;
}

.copy {
  color: #e8e2e6;
  cursor: default;
  font-size: 1.6em;
}
</style>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  left: 0;
  position: absolute;
  right: 0;
  transition: opacity .5s, transform .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(18px);
}
</style>
