import Vue from 'vue'

import { sync } from 'vuex-router-sync'
import VueApollo from 'vue-apollo'
import { createApolloClient } from './apollo'

import App from '@/App-pajaron.vue'
import router from '@/router/pajaron'
import store from '@/store/pajaron'

Vue.config.productionTip = false

Vue.use(VueApollo)

function createApp(context) {
  const apolloClient = createApolloClient(context.ssr)
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient
  })

  sync(store, router)
  
  router.beforeEach((to, from, next) => {
    store.commit('setIsRouteLoading', true)
    next()
  })

  // router.beforeResolve((to, from, next) => {
  //   next()
  // })

  router.afterEach(() => {
    store.commit('setIsRouteLoading', false)
  })

  return {
    app: new Vue({
      router,
      store,
      render: h => h(App),
      apolloProvider
    }),
    router,
    store,
    apolloProvider
  }
}

export default createApp
