import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueRouter from 'vue-router'

import Home from '@/views/Home-pajaron'

Vue.use(VueMeta)
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('@/views/Gallery'),
  },

  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
  },
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
})

export default router
