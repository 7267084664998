<template>
  <div :class="$style['max-width']">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'MaxWidth',
}
</script>

<style module lang="scss">
.max-width {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
}
</style>
