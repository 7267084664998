import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ambientColor: null,
    imageColor: {},
    isRouteLoading: false,
  },
  mutations: {
    setAmbientColor(state, value) {
      state.ambientColor = value
    },
    setImageColor(state, value) {
      state.imageColor[value.key] = value.value
    },
    setIsRouteLoading(state, value) {
      state.isRouteLoading = value
    },
  },
  actions: {},
  modules: {},
})
