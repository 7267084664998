<template>
  <Section :class="$style.profile">
    <H :class="$style.h1" :like="2">
      Web User Interface<br/>
      Designer + Engineer
    </H>

    <P :class="$style.p">
      Hallo! My name’s Hes Pajaron and I’m {{ description }}. With 7 years
      battling IE with jQuery and three years in to modern frontend tech
      (React.js, Vue.js). Loves tackling complex problems and coming up with
      elegant solutions. Inspired and motivated by great UI/UX.
    </P>
  </Section>
</template>

<script>
import H from '@/components/H'
import P from '@/components/P'
import Section from '@/components/Section'

export default {
  name: 'Profile',

  components: {
    H,
    P,
    Section,
  },

  props: {
    description: String,
  },
}
</script>

<style module lang="scss">
.profile {
  > div {
    @media (min-width: 768px) {
      display: flex;
    }
  }
}

.h1 {
  text-align: left;

  @media (min-width: 768px) {
    text-align: right;
  }
}

.p {
  text-align: left;

  @media (min-width: 768px) {
    margin-left: 40px;
  }
}
</style>
