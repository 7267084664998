// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".H_h_2t5Df{color:#e8e2e6;cursor:default;font-weight:300;line-height:1;margin-bottom:27px;margin-top:27px;text-transform:uppercase}.H_h1_bxQoi{font-size:7.2em}.H_h2_JVWur{font-size:4.8em}.H_h1_bxQoi+.H_h2_JVWur{margin-top:-27px}", ""]);
// Exports
exports.locals = {
	"h": "H_h_2t5Df",
	"h1": "H_h1_bxQoi",
	"h2": "H_h2_JVWur"
};
module.exports = exports;
