<template>
  <div>
    <LazyHydrate never>
      <Profile :description="dataDescription"/>
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration'

import Profile from '@/components/Profile'

export default {
  name: 'Home-pajaron',

  components: {
    LazyHydrate,
    Profile,
  },

  data() {
    return {
      dataDescription: 'a creative wizard who dabbles in the digital arts of frontend web development',
    }
  },

  computed: {
    computedDescription() {
      return `${this.dataDescription[0].toUpperCase()}${this.dataDescription.slice(1)}.`
    },
  },

  metaInfo() {
    return {
      meta: [{ name: 'description', content: this.computedDescription }]
    }
  }
}
</script>
