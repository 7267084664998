// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loading_span_2_1A8{display:inline-block}.Loading_i_1XOc6{display:block;opacity:0;transform:scale(0)}.Loading_--is-mounted_7qO6Z .Loading_i_1XOc6{opacity:1;transform:scale(1);transition:opacity .5s,transform .5s}@-webkit-keyframes Loading_spin_zkBED{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes Loading_spin_zkBED{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}.Loading_i_1XOc6:before{-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-name:Loading_spin_zkBED;animation-name:Loading_spin_zkBED;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-timing-function:linear;animation-timing-function:linear;border:2px solid #fff;border-top-color:transparent;border-radius:36px;content:\"\";display:block;height:32px;width:32px}", ""]);
// Exports
exports.locals = {
	"span": "Loading_span_2_1A8",
	"i": "Loading_i_1XOc6",
	"--is-mounted": "Loading_--is-mounted_7qO6Z",
	"spin": "Loading_spin_zkBED"
};
module.exports = exports;
