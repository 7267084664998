// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Section_section_3z-gA{overflow:hidden;padding-left:6.25%;padding-right:6.25%}.Section_padding_hy6JP{display:block;max-height:90px;overflow:hidden}.Section_padding_hy6JP:before{content:\"\";display:block;padding-top:9.648%}.Section_max-width_3X_nQ{padding-bottom:9.648%;padding-top:9.648%}.Section_--compact_1FMb0 .Section_max-width_3X_nQ{padding-bottom:0;padding-top:0}", ""]);
// Exports
exports.locals = {
	"section": "Section_section_3z-gA",
	"padding": "Section_padding_hy6JP",
	"max-width": "Section_max-width_3X_nQ",
	"--compact": "Section_--compact_1FMb0"
};
module.exports = exports;
