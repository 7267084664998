import { render, staticRenderFns } from "./Profile.vue?vue&type=template&id=01b8a3dd&"
import script from "./Profile.vue?vue&type=script&lang=js&"
export * from "./Profile.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./Profile.vue?vue&type=style&index=0&module=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)
this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  "75393f9a"
  
)

export default component.exports