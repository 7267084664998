// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".P_p_IoZ05{color:#fff;cursor:default;font-size:1.8em;line-height:1.6;margin-bottom:27px;margin-top:27px}", ""]);
// Exports
exports.locals = {
	"p": "P_p_IoZ05"
};
module.exports = exports;
