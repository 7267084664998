<template>
  <h1 :is="computedTag" :class="computedClass">
    <slot/>
  </h1>
</template>

<script>
export default {
  name: 'H',

  props: {
    level: {
      default: 1,
      type: Number,
    },
    like: {
      default: null,
      type: Number,
    },
  },

  computed: {

    computedClass() {
      return [
        this.$style.h,
        this.$style[`h${this.like || this.level}`],
      ]
    },

    computedTag() {
      return `h${this.level}`
    },

  },
}
</script>

<style module lang="scss">
.h {
  color: #e8e2e6;
  cursor: default;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 27px;
  margin-top: 27px;
  text-transform: uppercase;
}

.h1 {
  font-size: 7.2em;
}

.h2 {
  font-size: 4.8em;
}

.h1 + .h2 {
  margin-top: -27px;
}
</style>
